<template lang="pug">
  animate-push-on-panel.collection-loans.pb-px
    //- sections...
    template(v-for="list in [[current, 'Current Loans'], [past, 'Selected Past Loans']]")
      section.pt-30.mb-150(v-if="list[0].length")
        //- section header
        h2.px-20.md_px-40.text-20.xl_text-24.mb-40.md_mb-100 {{ list[1] }}
        //- entries...
        template(v-for="(entry, i) in list[0]")
          loan-entry-row.mb-90(:key="entry.slug", :entry="entry", :mediaFull="list[1].includes('Past')")

          //- (CTA: request loan)
          template(v-if="list[1].includes('Past') && i === 3")
            router-link.-mt-30.mb-60.md_my-100.block.bg-teal.w-full.min-h-130.md_min-h-16vw.flex.items-center.justify-center.text-18.md_text-20.xl_text-24(to="/collection/loans/borrow") Request a loan

</template>

<script>
import { api } from '@/store'
import queryLoans from '@/graphql/loans/queryLoans'
import LoanEntryRow from '@/components/loans/LoanEntryRow'
export default {
  name: 'CollectionLoans',
  components: { LoanEntryRow },
  data () {
    return {
      loans: []
    }
  },
  computed: {
    current () {
      return this.loans
        .filter(entry => new Date(entry.dateEnd) >= new Date())
        .sort((a, b) => new Date(a.dateStart) < new Date(b.dateStart) ? -1 : 0)
    },
    past () {
      return this.loans.filter(entry => new Date(entry.dateEnd) < new Date())
    }
  },
  methods: {
    async getLoans () {
      try {
        const resp = await api(queryLoans)
        this.loans = resp.data.entries
      } catch (e) {
        console.error(e)
      }
    }
  },
  created () {
    this.getLoans()
  },
  metaInfo () {
    return {
      title: 'Loans'
    }
  }
}
</script>

<style>
</style>
