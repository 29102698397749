import imageVideoClip from '@/graphql/common/imageVideoClip'

export default `
query Loans {
  entries (section: "loans", orderBy: "dateEnd DESC") {
    slug
    ... on loans_loans_Entry {
      dateStart
      dateEnd
      indexImage: programmeIndexImage {
        ${imageVideoClip(true)}
      }
      loanDetails {
        ... on loanDetails_detail_BlockType {
          type:__typename
          label
          text
        }
      }
      loanBodyBlocks {
        ... on loanBodyBlocks_bodyText_BlockType {
          type:__typename
          text
        }
        ... on loanBodyBlocks_carousel_BlockType {
          type:__typename
          slides {
            ... on slides_BlockType {
              image {
                ${imageVideoClip(true)}
              }
            }
          }
        }
      }
    }
  }  
}
`
