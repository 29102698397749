<template lang="pug">
  //- row
  article.loan-entry-row
    //-
    header.flex.flex-wrap.justify-end.px-20
      //- index image
      .w-full.md_mr-1x12(:class="{'md_w-5x12 md_px-20': !mediaFull, 'md_w-6x12 md_transform md_-translate-x-20': mediaFull}")
        figure.w-full.relative.group(v-for="(image, i) in entry.indexImage", :class="{'md_4x5': isPrtImg(image), 'mt-40 md_mt-90': i}")
          image-video-clip(:bg="false", :media="image")
          image-hover-caption(:image="image")

      //- details
      ul.w-full.md_w-5x12.mt-30.md_mt-0
        //- details...
        li.flex.mb-25(v-for="(detail, i) in entry.loanDetails", :class="{'mb-20 md_mb-50': !i}")
          //- label
          .w-1x4.xl_w-1x5.text-10.md_text-14.md_px-20
            h6(v-if="detail.label && detail.label.length") {{ detail.label }}
          //- text
          .flex-1.text-14.md_text-18.md_px-20
            div(v-html="detail.text")
            div(v-if="detail.label === 'Exhibition'") {{ $store.getters.dateSpan(entry.dateStart, entry.dateEnd, '–') }}
            //- read more
            btn-read-more.mt-30.md_mt-50(@click.native="more = !more", v-if="hasMore && i === entry.loanDetails.length - 1")

    //- (body / more)
    slice-body.-mt-30.mb-50.md_mb-100(v-if="more", :slices="entry.loanBodyBlocks", :templates="templates")
</template>

<script>
import SliceBodyText from '@/slices/SliceBodyText'
import SliceCarousel from '@/slices/SliceCarousel'
export default {
  name: 'LoanEntryRow',
  props: ['entry', 'mediaFull'],
  data () {
    return {
      more: false,
      templates: {
        loanBodyBlocks_bodyText_BlockType: SliceBodyText,
        loanBodyBlocks_carousel_BlockType: SliceCarousel
      }
    }
  },
  computed: {
    hasMore () {
      return this.entry.loanBodyBlocks.length
    }
  },
  methods: {
    isPrtImg (img) {
      return img?.height > img?.width
    }
  }
}
</script>

<style>
</style>
